import React, {Component} from 'react';
import teamPhoto1 from '../../../assets/img/aboutUs/teamPhoto1.jpg';
import teamPhoto2 from '../../../assets/img/aboutUs/teamPhoto2.jpg';

export default class AboutUs extends Component {

    textOffSetImage( imageLocation ){
        if (imageLocation=='right'){
            return(
                <div className="row">
                    <div className="col">
                        <h1>About JJ Justice</h1>
                        <p>JJ Justice is a Virginia based collective group of licensed Private Investigators and Cyber Specialists who are dedicated to uncovering the truth. Because of their diversity and wide array of experience, they offer the most thorough and comprehensive services to provide you with the information and answers you seek. No matter the case, JJ Justice provides their clients with dedication and care in even the most sensitive of cases.</p>
                    </div>
                    <div className="col colImage">
                        <div className="offsetElement">
                            <div className="orangeBackgroundLeft"></div>
                            <div className="image">
                                <img src={teamPhoto1} alt="Overlay of the main image for the header section"/>
                            </div>
                        </div>
                    </div>
                    </div>
                ); 
            }else{
                return(
                    <div className="row reverse-wrap">
                        <div className="col colImage">
                            <div className="offsetElement">
                                <div className="orangeBackgroundRight"></div>
                                <div className="image">
                                    <img src={teamPhoto2} alt="Overlay of the main image for the header section"/>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <h1>About JJ Justice</h1>
                            <p>JJ Justice utilizes tactics that are discreet, professional and designed to provide results. Whether you are looking for justice regarding an individual or wrongdoing, gathering information or performing a background check, JJ Justice utilizes effective and discreet information gathering techniques and will exhaust all avenues to provide you with the facts and information that you seek. Our information is provided to you in accessible, court-submittable documentation allowing you to be rest assured your case is the most capable hands.</p>
                        </div>
                    </div>

            );
            }
        
    }
    
    render(){
        var imageLocation;
        return(
            <div className="about-us">
                <div className="content_container">
                    {this.textOffSetImage(imageLocation='right')}          
                    {this.textOffSetImage(imageLocation='left')}  
                </div>
            </div>
        );    
    }
}
