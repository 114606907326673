import React, { Component, PropTypes } from 'react';
import {connect} from 'react-redux';
import { hideModal } from '../../actions/modalAction';
import Modal from '../modal/Modal';

const SCREEN_THANK_YOU = 'thank_you'

class ContactFormConfirmationModal extends Component {

    constructor (props) {
        super(props)
        this.state = {
            currentScreen : SCREEN_THANK_YOU,
            errors: {}
        }
    }

    render() {
        const { currentScreen } = this.state
        
        let screen = this.renderThankYouScreen()

        switch (currentScreen) {
            case SCREEN_THANK_YOU:
                screen = this.renderThankYouScreen()
                break
            default:
                this.renderThankYouScreen()
        }


        return(
            <Modal
                className={`contact-confirmation-modal`}
                onCloseClick={this.props.hideModal}
            >
                {screen}
            </Modal>
        )
    }
    
    renderThankYouScreen () {
        const {hideModal} = this.props;
        return (
            <div className="content center">
                <div className="modal-header">
                    <h1>Thank you for your message, we will be in contact soon</h1>
                </div>
                <div className='buttons-container center'>
                    <button className='button fill' onClick={hideModal}>Close</button>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state, ownProps) => {

    return {
    }
}

const mapDispatchToProps = dispatch => ({
    hideModal               : () => dispatch(hideModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormConfirmationModal);
