import * as ActionTypes from '../ActionTypes';

const defaultState={
    chat_open: false,
    message: "",
    handle: "Your Name",
    chatOutput: []
}

export default (state=defaultState, action) => {
    switch (action.type){
        case ActionTypes.CHAT_OPEN:
            return Object.assign({}, state, 
                {
                    chat_open: true
                }
            );
        
        case ActionTypes.CHAT_CLOSE:
            return Object.assign({}, state, 
                {
                     chat_open: false
                }
            );
        
        case ActionTypes.CHAT_CLEAR:
            return Object.assign({}, state, 
                {
                     message: ""
                }
            );
        case ActionTypes.CHAT_OUTPUT_MESSAGE_ADD:
            return Object.assign({}, state, 
                {
                    ChatOutput: state.chatOutput.push(action.payload)
                }
            );
        default:
            return state;
    }
}
