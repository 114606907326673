import * as ActionTypes from '../ActionTypes';

const defaultState={
    sidebar_open: false
}

export default (state=defaultState, action) => {
    switch (action.type){
        case ActionTypes.SIDEBAR_OPEN:
            return Object.assign({}, state, 
                {
                    sidebar_open: true
                }
            );
        
        case ActionTypes.SIDEBAR_CLOSE:
            return Object.assign({}, state, 
                {
                    sidebar_open: false
                }
            );
        default:
            return state;
    }
}
