import * as ActionTypes from '../ActionTypes';

export const createLead = (postData) => (dispatch) => {
    return new Promise((resolve, reject) => {
        fetch('https://api.jjjustice.com/api/v1/leads/create',{
            method:'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
            .then( res => res.json())
            .then( post => {
                dispatch({
                    type: ActionTypes.NEW_LEAD,
                    payoad: post
                });

                resolve(true);
            });
    });
};

