import React, {Component} from 'react';
import img_backgroundChecks from '../../../assets/img/services/backgroundChecks.jpg';
import img_childCustody from '../../../assets/img/services/childCustody.jpg';
import img_childSupport from '../../../assets/img/services/childSupport.jpg';
import img_divorce from '../../../assets/img/services/divorce.jpg';
import img_employeeTheft from '../../../assets/img/services/employeeTheft.jpg';
import img_infidelity from '../../../assets/img/services/infidelity.jpg';
import img_insuranceFraud from '../../../assets/img/services/insuranceFraud.jpg';
import img_missingPersons from '../../../assets/img/services/missingPersons.jpg';
import img_onlineDating from '../../../assets/img/services/onlineDating.jpg';

export default class Services extends Component {

    render(){
        return(
            <div className="services">
                <div className="serviceContainer">
                    <div className="headerRow">
                        <h1>Services</h1>
                    </div>
                    <div className="servicesRow">
                        <div className="serviceTile">
                            <h2>INFIDELITY</h2>
                            <img src={img_infidelity} alt="Main header image for the header section"/> 
                        </div>
                        <div className="serviceTile">
                            <h2>CHILD CUSTODY INVESTIGATIONS</h2>
                            <img src={img_childCustody} alt="Main header image for the header section"/> 
                        </div>
                        <div className="serviceTile">
                            <h2>CHILD SUPPORT</h2>
                            <img src={img_childSupport} alt="Main header image for the header section"/> 
                        </div>
                        <div className="serviceTile">
                            <h2>INSURANCE FRAUD INVESTIGATIONS</h2>
                            <img src={img_insuranceFraud} alt="Main header image for the header section"/> 
                        </div>
                        <div className="serviceTile">
                            <h2>BACKGROUND CHECKS</h2>
                            <img src={img_backgroundChecks} alt="Main header image for the header section"/> 
                        </div>
                    </div>
                    <div className="servicesRow">
                        <div className="serviceTile">
                            <h2>EMPLOYEE THEFT</h2>
                            <img src={img_employeeTheft} alt="Main header image for the header section"/> 
                        </div>
                        <div className="serviceTile">
                            <h2>ONLINE DATING</h2>
                            <img src={img_onlineDating} alt="Main header image for the header section"/> 
                        </div>
                        <div className="serviceTile">
                            <h2>MISSING PERSONS & RUNAWAY TEENS</h2>
                            <img src={img_missingPersons} alt="Main header image for the header section"/> 
                        </div>
                        <div className="serviceTile">
                            <h2>Divorce</h2>
                            <img src={img_divorce} alt="Main header image for the header section"/> 
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
