import React, { Component } from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import HamburgerMenuWrapper from './burger';
import  NavbarDesktop from './navbarDesktop';
import logo from '../../../assets/img/logo.jpg';


export default class Navbar extends Component {

    
    
    render() {


        return (
            <div className="navbar">
                <NavbarDesktop />
                <HamburgerMenuWrapper />
            </div>
        );
    }
}
