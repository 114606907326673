import React from 'react';
import {Field, reduxForm} from 'redux-form';


let ContactForm = props => {
    const {handleSubmit} = props
    return (
        <form onSubmit={handleSubmit}>
            <div className="contact_form">
                <div className="row">
                    <div className="col">
                        <h2>First Name</h2>
                        <Field name="firstName" component="textarea" type="text" />
                    </div>
                    <div className="col">
                        <h2>Last Name</h2>
                        <Field name="lastName" component="textarea" type="text" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h2>Phone Number</h2>
                        <Field name="phone" component="textarea" type="text" />
                    </div>
                    <div className="col">
                        <h2>E-mail</h2>
                        <Field name="email" component="textarea" type="text" />
                    </div>
                </div>
                <div className="row">
                    <div className="col message">
                        <h2>Message</h2>
                        <Field name="message" component="textarea" type="text" />
                    </div>
                </div>
                <button type="submit" className="submit_button">Submit</button>
            </div>
        </form>
    );
}

ContactForm = reduxForm({
    form: 'ContactUsForm'
})(ContactForm);

export default ContactForm;
