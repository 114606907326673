import * as actionTypes from '../ActionTypes';

const initialState = {
    modalType: null,
    modalProps: {},
    isModalSpinnerVisible: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_MODAL:
            return Object.assign({}, state, {
                modalType: action.modalType,
                modalProps: action.modalProps
            });

        case actionTypes.HIDE_MODAL:
            return initialState;

        case actionTypes.SHOW_MODAL_SPINNER:
            return Object.assign({}, state, {
                isModalSpinnerVisible: true
            });

        case actionTypes.HIDE_MODAL_SPINNER:
            return Object.assign({}, state, {
                isModalSpinnerVisible: false
            });

        default:
            return state
    }
}
