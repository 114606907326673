import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
import {reducer as formReducer} from 'redux-form';

import * as reducers from './reducers/index';

import thunkMiddleware from 'redux-thunk';

let createStoreWithMiddleware = applyMiddleware(thunkMiddleware)(createStore);

const appReducer = combineReducers({
    ...reducers,
    form: formReducer
});

let createdStore = null;

export const configureStore = (initialState = {}) => {
    createdStore = createStoreWithMiddleware(appReducer, initialState,
        //redux dev tools
        window.devToolsExtension ? window.devToolsExtension() : undefined
    );
    return createdStore;
};

export const getStore = () => createdStore;
