import React, {PropTypes} from 'react';
import cn from 'classnames';
import ReactModal from 'react-modal';
//import SectionSpinner from '../spinner/SectionSpinner';
import crossImgGrey from '../../../img/ui/cross-grey.svg';
import crossImgWhite from '../../../img/ui/cross-white.svg';

export default class Modal extends React.Component {
    timer = null

        /*
    static propTypes = {
        className            : PropTypes.string.isRequired,
        isCloseButtonVisible : PropTypes.bool,
        isSpinnerVisible     : PropTypes.bool,
        onCloseClick         : PropTypes.func,
        title                : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }
    */

    static defaultProps = {
        isCloseButtonVisible : true,
    }

    constructor (props) {
        super(props)

        this.state = {
            isFullHeightPopup: false
        }
    }

    // TODO: could be refactored. The method is used only outside the component.
    // It is not a good practice to do so. The ref can be passed through some handler within props
    getOverlayNode = () => {
        return this.modalComponent.portal.refs.overlay;
    }

    componentWillMount() {
        window.addEventListener('orientationchange', this.checkPopupHeight);
    }

    componentDidMount() {
        this.checkPopupHeight()
    }

    componentWillUnmount() {
        window.removeEventListener('orientationchange', this.checkPopupHeight);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(this.props.children != nextProps.children) {
            this.checkPopupHeight()
        }
    }

    checkPopupHeight = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            if(this.modalComponent && this.modalComponent.portal) {
                const popupHeight = this.modalComponent.portal.refs.content
                    ? parseInt(window.getComputedStyle(this.modalComponent.portal.refs.content).height)
                    : 0
                if(window.innerHeight <= popupHeight) {
                    if(!this.state.isFullHeightPopup) {
                        this.setState({isFullHeightPopup: true})
                    }
                } else {
                    if(this.state.isFullHeightPopup) {
                        this.setState({isFullHeightPopup: false})
                    }
                }
            }
        }, 100)
    }


    render () {
        const {
            className,
            isCloseButtonVisible,
            onCloseClick,
            isSpinnerVisible,
            title,
            description,
            children,
            ...other
        } = this.props

        const hasHeader = !!(title || description)

        const closeBtnImg = hasHeader ? crossImgWhite : crossImgGrey

        return (
            <ReactModal
                isOpen={true}
                overlayClassName={cn('modal-overlay', `${className}-overlay`)}
                className={cn('modal', className, {'with-header' : hasHeader}, {'full-height' : this.state.isFullHeightPopup})}
                contentLabel={className}
                {...other}
                ref={node => this.modalComponent = node}
            >
                {hasHeader ? this.renderHeader() : null}
                {isCloseButtonVisible ? this.renderCloseButton(closeBtnImg) : null}
                {children}
                {/*isSpinnerVisible && <SectionSpinner />*/}
            </ReactModal>
        )
    }

    renderHeader () {
        const { title, description } = this.props

        return (
            <div className='header'>
                {title ? <div className='header-title'>{title}</div> : null}
                {description ? <div className='header-description'>{description}</div> : null}
            </div>
        )
    }

    renderCloseButton (crossImage) {
        return (
            <button className='close-button' onClick={this.props.onCloseClick}>
                <img src={crossImage} />
            </button>
        )
    }
}
