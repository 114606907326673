import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reset} from 'redux-form';
import ContactForm from './contactForm'; 
import * as modalTypes  from '../../constants/modalTypes';
import {showModal} from '../../actions/modalAction';
import {createLead} from '../../actions/leadAction';

class Contact extends Component{

    constructor(props){
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    
    handleSubmit(e){
        e.preventDefault();
        const {createLead, contactForm, showContactSuccessModal, formReset} = this.props; 

        if (contactForm){
            const name = contactForm.firstName + " " + contactForm.lastName ;
            
            const postData = {
               name : name, 
               email : contactForm.email, 
               phone : contactForm.phone, 
               message : contactForm.message
            }
           
            createLead(postData);

            formReset("ContactUsForm");
            showContactSuccessModal();
        }
    }

    render(){
        return(
            <div className="contact">
                <div className="content_container">
                    <div className="header_row">
                        <h1>Contact Us</h1>
                    </div>
                    <ContactForm handleSubmit = {this.handleSubmit}/>
                </div>
            </div>

        );
    }
}


const mapStateToProps = (state) => {
    // Deal with elements which may not initialised upon first load 
    const contactForm =  state.form.ContactUsForm ? state.form.ContactUsForm.values : null

    return{
        contactForm : contactForm,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        createLead: (postData) => dispatch(createLead(postData)),
        showContactSuccessModal: () => dispatch(showModal(modalTypes.CONTACT_FORM_CONFIRMATION)),
        formReset: (form) => dispatch(reset(form))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
