import React, {Component} from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';

const Testimonials = () => {

    const params = {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        },
        pagination: {

            el: '.swiper-pagination',
            clickable: true
        },
        navigation: {
            nextEl: 'swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
    }

    return(
        <div className="testimonials">
            <h1>Testimonials</h1>
            <Swiper {...params}>
                <div>
                    <div className="slider_container">
                        <div className="slide">
                            <p>When I first started to suspect one of my employees was stealing from my business, I wasn’t sure where to turn. A trusted friend suggested contacting JJ Justice to open an investigation into the missing finances. My first conversation with the JJ Justice team immediately put me at ease during a trying time in my life. They meticulously and discreetly investigated the situation and were able to identify the untrustworthy employee and provide me with documents I could use in court to get justice. I cannot recommend the team at JJ Justice more.</p>
                            <p className="client_name">Anonymous, Small Business Owner, Maryland</p>
                        </div>
                    </div>
                </div>
            </Swiper>
        </div>
    );
}

export default Testimonials;
