import React, { Component } from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import HamburgerMenuWrapper from './burger';
import logo from '../../../assets/img/logo.jpg';


export default class NavbarDesktop extends Component {

    render(){
       return(
           <div className="navbar-desktop">
                <div className="section2">
                    <div className="content-container">
                        <div className="logo" >
                            <img src={logo} height="130"/> 
                        </div>
                        <div className="menu-items-container"> 
                            <Link to="about-us" smooth={true}> About </Link>
                            <Link to="services" smooth={true}>Services</Link>
                            <Link to="testimonials" smooth={true}>Testimonials</Link>
                            <Link to="contact" smooth={true}>Contact</Link>
                        </div> 
                    </div>
                </div>
            </div>
       )

    }
}
