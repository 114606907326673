import React, { Component } from 'react';
import ContactConfirmationModal from '../contact/ContactConfirmationModalContainer';
import { connect } from 'react-redux';
import * as modalTypes from '../../constants/modalTypes';

const MODAL_COMPONENTS = {
    [modalTypes.CONTACT_FORM_CONFIRMATION]: ContactConfirmationModal
};

class GlobalModalContainer extends Component {
    render() {
        const {modalType, modalProps} = this.props;

        if (!modalType) {
            return null;
        }

        const SpecificModal = MODAL_COMPONENTS[modalType];
        return <SpecificModal {...modalProps} />
    }
}

const mapStateToProps = state => ({
    modalType: state.Modal.modalType,
    modalProps: state.Modal.modalProps
});

export default connect(mapStateToProps)(GlobalModalContainer);
