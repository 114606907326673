import React, { Component } from 'react';
import imageOverlay from '../../../assets/img/header/imageOverlay.png'
import mainHeaderImage from '../../../assets/img/header/mainHeaderImage.jpg'

class Header extends Component {
    render() {
        return (
            <div className="header">
                <div className="mainHeaderImage">
                    <img src={mainHeaderImage} alt="Main header image for the header section"/> 
                </div>
                    
                <div className="overlayLayer">
                    <img src={imageOverlay} alt="Overlay of the main image for the header section"/>
                </div>
                <div className="overlayText">
                    <h1>INVESTIGATING<br/>TO UNCOVER<br/>THE TRUTH</h1>
                </div>
                
            </div>
        );
    }
}
export default Header;
