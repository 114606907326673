import React from 'react';
//import Chat from './scripts/compoments/header/chat.js';
import Header from './scripts/components/header/header.js';
import Navbar from './scripts/components/header/navbar.js';
import AboutUs from './scripts/components/header/aboutUs.js';
import Services from './scripts/components/header/services.js';
import Testimonials from './scripts/components/header/testimonials.js';
import Contact from './scripts/components/header/contact.js';
import Footer from './scripts/components/header/footer.js';
import GlobalModalContainer from './scripts/containers/modal/GlobalModalContainer';
import {configureStore} from './scripts/configureStore';
import {Provider} from 'react-redux';

const store = configureStore();

const App = () => (
    <Provider store={store}>
        <div>
            <Navbar />
            <Header />
            <AboutUs />
            <Services />
            <Testimonials />
            <Contact />
            <Footer />
            <GlobalModalContainer />
        </div>
    </Provider>
);
export default App;
