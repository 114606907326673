import * as ActionTypes from '../ActionTypes';

const defaultState={
    lead: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: ""
    }
}

export default (state=defaultState, action) => {
    switch (action.type){
        case ActionTypes.NEW_LEAD:
            return Object.assign({}, state, 
                {
                    lead:action.payload 
                }
            );
        
        default:
            return state;
    }
}
