export const SIDEBAR_OPEN = 'SIDEBAR_OPEN';
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE';
export const CHAT_OPEN = 'CHAT_OPEN';
export const CHAT_CLOSE = 'CHAT_CLOSE';
export const CHAT_CLEAR = 'CHAT_CLEAR';
export const NEW_LEAD = 'NEW_LEAD';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL_SPINNER = 'SHOW_MODAL_SPINNER';
export const HIDE_MODAL_SPINNER = 'HIDE_MODAL_SPINNER';
export const CHAT_OUTPUT_MESSAGE_ADD = 'CHAT_OUTPUT_MESSAGE_ADD';
