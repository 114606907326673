import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Link, animateScroll as scroll } from "react-scroll";
import {openSidebar, closeSidebar} from '../../actions/navbarAction';
import burgerPhoto from '../../../assets/img/navbar/burgerDark.png';
import burgerExitPhoto from '../../../assets/img/navbar/closeImageDark.png';
import logo from '../../../assets/img/logoDark.png';


class HamburgerMenuWrapper extends Component {

    toggleMenu(){
        const {sidebarIsOpen, sidebarOpen, sidebarClose} = this.props;
        {sidebarIsOpen ? sidebarClose() : sidebarOpen()};
    }

    handleClick() {
        this.toggleMenu();
    }

    render(){
        const {sidebarIsOpen} = this.props;

        return(
            <div className="sidebar">
                <div className="top-row">
                    <div className="logo">
                        <img src={logo} height="45"/>
                    </div>
                    <button onClick={()=>this.handleClick()} className="burger-menu" > <img src={sidebarIsOpen ? burgerExitPhoto : burgerPhoto} height="45" /> </button>
                </div>

                {sidebarIsOpen ? 
                    <div className="items">
                        <Link onClick={()=>this.handleClick()} to="about-us" smooth={true}>About Us</Link>
                        <Link onClick={()=>this.handleClick()} to="services" smooth={true}>Services</Link>
                        <Link onClick={()=>this.handleClick()} to="testimonials" smooth={true}>Testimonials</Link>
                        <Link onClick={()=>this.handleClick()} to="contact" smooth={true}>Contact Us</Link>
                    </div>
                    : null
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return{
        sidebarIsOpen : state.Navbar.sidebar_open 
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        sidebarOpen: () => dispatch(openSidebar()),
        sidebarClose: () => dispatch(closeSidebar())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerMenuWrapper);
