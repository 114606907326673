import * as ActionTypes from '../ActionTypes';

export const showModal = (modalType, modalProps) => ({
    type: ActionTypes.SHOW_MODAL,
    modalType,
    modalProps
});

export const hideModal = () => ({
    type: ActionTypes.HIDE_MODAL
});

export const showModalSpinner = () => ({
    type: ActionTypes.SHOW_MODAL_SPINNER
});

export const hideModalSpinner = () => ({
    type: ActionTypes.HIDE_MODAL_SPINNER
});

export const addModalSpinnerToRequest = (invokeRequest) => (dispatch) => {
    dispatch(showModalSpinner());

    return invokeRequest()
        .then(() => dispatch(hideModalSpinner()))
        .catch((error) => {
            dispatch(hideModalSpinner());
            throw error;
        })
};
